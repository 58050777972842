
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapGetters } from 'vuex';

import { langs } from '@/constants';
import TiptapEditor from '@/components/Tiptap.vue';
import DetailsMenu from '@/components/menus/DetailsMenu.vue';
import ImagesList from '@/components/lists/ImagesList.vue';
import DetailsList from '@/components/lists/DetailsList.vue';
import ImageUpload from '@/components/dialogs/ImageUpload.vue';
import DeleteServiceDialog from '@/components/dialogs/DeleteService.vue';

import { Location } from '@/entities/Location';
import { ServiceDetail } from '@/entities/directory/ServiceDetail';
import { Service } from '@/entities/directory/Service';

@Component({
  computed: mapGetters([
    'location'
  ]),

  components: {
    ImageUpload,
    DeleteServiceDialog,
    TiptapEditor,
    DetailsMenu,
    ImagesList,
    DetailsList
  }
})
export default class ServicesCreateView extends Vue {
  location!: Location;

  service: Service = {
    title: "",
    description: "",
    lang: langs[0].abbr,
    enabled: true,
    image_url: "",
    weight: 0,
    details: [] as ServiceDetail[]
  } as Service;

  details: ServiceDetail[] = [];
  detail: ServiceDetail = {
    title: "",
    description: ""
  } as ServiceDetail;
  detailEdit = false;

  valid = false;
  fabMenu = false;
  deleteDialog = false;
  imageDialog = false;

  langs = langs;

  weight = [
          0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40
        ];

  nameRules = [
    (v: string) => !!v || 'Name is required',
    (v: string) => (v && v.length <= 64) || 'Name can\'t be longer than 64 characters'
  ];

  $refs!: {
    detailsMenu: DetailsMenu,
    editor: TiptapEditor
  }

  get serviceId() {
    if (this.$route.params.service !== undefined) {
      return Number(this.$route.params.service);
    }

    return null;
  }

  mounted() {
    this.fetchService();
  }

  fetchService() {
    if (this.serviceId !== null) {
      this.$services.getById(this.serviceId).then(service => {
        this.service = service;
        this.$refs.editor.setText(this.service.description);
      });
    }
  }

  openMenu(event: MouseEvent, index: number) {
    this.$refs.detailsMenu.open(event, this.service.details[index]);
  }

  /**
   * Create the Service
   */
  createService() {
    if (this.serviceId !== null) return;

    this.$services.create(
      this.service.title,
      this.service.image_url,
      this.service.description,
      this.service.lang,
      this.service.weight,
      this.location.uuid,
      this.service.details
    ).then(() => {
      this.$router.replace("/services");
    });
  }

  /**
   * Save the Service
   */
  saveService() {
    if (this.serviceId === null) return;

    this.$services.edit(this.service, {
      name: this.service.title,
      description: this.service.description,
      image_url: this.service.image_url,
      lang: this.service.lang as "en" | "fr",
      weight: this.service.weight,
      details: this.service.details,
      enabled: this.service.enabled
    });
  }

  /**
   * Delete this Service
   */
  deleteService() {
    if (this.serviceId === null) return;

    this.$services.delete(this.service).then(() => {
      this.$router.replace("/services");
    });
  }

  /**
   * Add a detail to the current service
   */
  addDetail() {
    if (this.detail.title.trim().length === 0 || this.detail.description.trim().length === 0) {
      return;
    }

    if (this.detailEdit === false) {
      this.service.details.push(this.detail);
    }

    this.detailEdit = false;
    this.detail = { title: "", description: "" } as ServiceDetail;
  }

  /**
   * Edit a Detail
   */
  editDetail(detail: ServiceDetail) {
    this.detailEdit = true;
    this.detail = detail;
  }

  /**
   * Delete a Detail
   */
  removeDetail(detail: ServiceDetail) {
    this.service.details.splice(this.service.details.indexOf(detail), 1);
    this.detail = { title: "", description: "" } as ServiceDetail;
  }
}
