
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

import { ServiceDetail } from '@/entities/directory/ServiceDetail';

@Component
export default class DetailsMenu extends Vue {
  @Prop({ required: true })
  edit!: Function;

  @Prop({ required: true })
  remove!: Function;

  detail?: ServiceDetail;

  show = false;
  menux = 0;
  menuy = 0;

  open(e: MouseEvent, detail: ServiceDetail) {
    e.preventDefault();
    this.detail = detail;
    this.show = false;
    this.menux = e.clientX;
    this.menuy = e.clientY;

    this.$nextTick(() => {
      this.show = true;
    });
  }
}
