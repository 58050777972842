
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

import { categories } from '@/constants';
import { Image } from '@/entities/images/Image';

@Component({

})
export default class ImagesList extends Vue {
  @Prop({ required: true })
  value!: Image;

  @Prop({ default: false })
  filter!: boolean;

  search: string = "";
  images: Image[] = [];

  categories: string[] = categories;
  selectedCategories: string[] = [];

  set image(value: Image) {
    this.$emit('input', value)
  }

  get image() {
    return this.value;
  }

  mounted() {
    this.fetchImages();
  }

  fetchImages() {
    this.$images.list().then(images => {
      this.images = images;
    });
  }

  get filtered() {
    return this.$helpers.filterImages(this.images, this.search, this.selectedCategories);
  }
}
