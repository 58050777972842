
import Vue from 'vue';
import Component from 'vue-class-component';

//@ts-ignore
import { Editor, EditorContent, EditorMenuBar } from 'tiptap'
import {
  HardBreak,
  Heading,
  Bold,
  Code,
  Italic,
  Link,
  Underline,
  History,
//@ts-ignore
} from 'tiptap-extensions' 
import { Prop } from 'vue-property-decorator';

@Component({
  components: {
    EditorContent,
    EditorMenuBar,
  }
})
export default class TiptapEditor extends Vue {
  @Prop()
  value!: string;

  editor = new Editor({
    extensions: [
      new HardBreak(),
      new Heading({ levels: [1, 2, 3] }),
      new Link(),
      new Bold(),
      new Code(),
      new Italic(),
      new Underline(),
      new History(),
    ],
    content: this.text,
    onUpdate: ((update: any) => {
      let html = update.getHTML();
      this.text = html;
    })
  });

  setText(text: string) {
    this.editor.setContent(text);
  }

  beforeDestroy() {
    this.editor.destroy();
  }

  get text() {
    return this.value;
  }

  set text(value) {
    this.$emit('input', value);
  }
}
