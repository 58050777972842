
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class DeleteServiceDialog extends Vue {
  @Prop({ required: true })
  value!: boolean;

  @Prop({ required: true })
  remove!: Function

  get show() {
    return this.value;
  }

  set show(value) {
    this.$emit('input', value);
  }
}
