
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

import draggable from 'vuedraggable'

import { ServiceDetail } from '@/entities/directory/ServiceDetail';

@Component({
  components: {
    draggable
  }
})
export default class DetailsList extends Vue {
  @Prop({ required: true })
  details!: ServiceDetail[];

  @Prop({ required: true })
  open!: Function

  show = false;
}
